import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Пропозиції | Drift Brix
			</title>
			<meta name={"description"} content={"У тренажерному залі Drift Brix Gym ми прагнемо надати низку пропозицій, розроблених для досягнення ваших фітнес-цілей і покращення загального самопочуття"} />
			<meta property={"og:title"} content={"Пропозиції | Drift Brix"} />
			<meta property={"og:description"} content={"У тренажерному залі Drift Brix Gym ми прагнемо надати низку пропозицій, розроблених для досягнення ваших фітнес-цілей і покращення загального самопочуття"} />
			<meta property={"og:image"} content={"https://driftwaybrix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://driftwaybrix.com/img/1616477.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://driftwaybrix.com/img/1616477.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://driftwaybrix.com/img/1616477.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://driftwaybrix.com/img/1616477.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://driftwaybrix.com/img/1616477.png"} />
			<meta name={"msapplication-TileImage"} content={"https://driftwaybrix.com/img/1616477.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 120px 0" sm-padding="60px 0 60px 0" quarkly-title="Cards-8" background= "#f9f2f0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="flex-start"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 70px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					text-align="left"
					sm-font="normal 700 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					width="50%"
					lg-width="100%"
				>
					Наші послуги
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
						background= "#fff"
					>
						<Box>
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Комплексні рішення для фітнесу
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								У тренажерному залі Drift Brix Gym ми прагнемо надати низку пропозицій, розроблених для досягнення ваших фітнес-цілей і покращення загального самопочуття. Наші комплексні пропозиції гарантують, що у вас є все, що вам потрібно для успіху, незалежно від того, чи хочете ви наростити силу, покращити витривалість або знайти баланс і гнучкість.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
						background= "#fff"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Експертні тренери та персонал
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								Наша команда сертифікованих тренерів і доброзичливий персонал є основою Drift Brix Gym. Кожен тренер має багаті знання та досвід, пропонуючи персоналізовані рекомендації, які допоможуть вам досягти віх вашої форми. Незалежно від того, чи потрібні вам індивідуальні тренінги чи групові заняття, наші експерти готові підтримати вас на кожному кроці.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
						background= "#fff"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
							Персональні тренування							
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								Індивідуальні тренування: індивідуальні плани вправ, які відповідають вашим конкретним фітнес-цілям і поточному рівню.
<br/><br/>
Індивідуальні заняття: індивідуальна увага експертів-тренерів, щоб гарантувати, що ви отримаєте максимум від кожного тренування.
<br/><br/>
Відстеження прогресу: регулярне оцінювання для моніторингу вашого прогресу та коригування програми за потреби.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1" background= "#f9f2f0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://driftwaybrix.com/img/7.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://driftwaybrix.com/img/8.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Зробіть перший крок до того, щоб стати здоровішим і сильнішим, відкривши для себе все, що може запропонувати Drift Brix Gym. Наша команда експертів і найсучасніше обладнання тут, щоб підтримати вас на кожному кроці. Приєднуйтесь до нас і покращте свій фітнес-досвід сьогодні.
				</Text>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.FooterMenu />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});